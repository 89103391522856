
// export const Base_Url = "http://172.104.40.242:9898/api/";

// export const webHost = "http://172.104.40.242:9898"
// export const webHostUi = "http://172.104.40.242:8989"

export const Base_Url = "https://stagingapi.ncisribbonchallenge.sg/api/";

export const webHost = "https://stagingapi.ncisribbonchallenge.sg"; // api address
export const webHostUi = "https://staging.ncisribbonchallenge.sg";  // UI address

